import "@scss/style.scss";

import { graphql, useStaticQuery } from "gatsby";

import Img from "gatsby-image";
import React from "react";

//import themeFeaturesQuotaIllustration from '@images/features-quote-illustration.svg';

const Projects = (props) => {
  const images = useStaticQuery(graphql`
    query {
      features01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "features" }
      ) {
        childImageSharp {
          fluid(maxWidth: 790, maxHeight: 103) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      fracre01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "cali-real-estate" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      ug01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "uganda-maize" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      ccpa01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "ccpa-screenshot" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      supplychain02: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "rice" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      supplychain03: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "device_data" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      fintech01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "alice-pasqual-Olki5QpHxts-unsplash" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      fintech02: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "SMECreditChain" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      fintech03: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "blockchaintech" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      smartcities01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "cosmic-timetraveler-zh_ofCt2r9c-unsplash" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      smartcities02: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "recycling-bins-373156_1280" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      healthcare02: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "owen-beard-DK8jXx1B-1c-unsplash" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      healthcare03: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "pentaHealth" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }

      un01: file(relativeDirectory: { eq: "features" }, name: { eq: "UN01" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }

      dev01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "pentax" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }

      impact02: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "trustedVoices" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      impact03: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "colorado" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      entertain01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "lfe" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      supplychain04: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "rodrigo-flores-T5qjs-63kqQ-unsplash" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      supplychain05: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "dylan-mcleod-4zqvu9dyvSk-unsplash" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      dev02: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "iso_tc307_meeting" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      un02: file(relativeDirectory: { eq: "features" }, name: { eq: "oecd" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
    }
  `);
  const data = {
    title: `Leader in Data Management Solutions`,
    // text: `Empowering Businesses and Social Applications through Blockchain`,
    text: ``,
    img: `features01`,
    featuresExtended: [
      {
        id: `pentafracre01`,
        name: `pentafracre01`,
        img: `fracre01`,
        title: `Fractional Real Estate Ownership`,
        text: `California real estate companies use the Penta blockchain to power fractional ownership platforms.`,
      },
      {
        id: `pentaug01`,
        name: `pentaug01`,
        img: `ug01`,
        title: `Uganda Ministry of Agriculture`,
        text: `Penta is a proud partner of the Uganda Ministry of Agriculture. We are bringing trusted agritech solutions to Kasese, Uganda.`,
      },
      {
        id: `pentaccpa01`,
        name: `pentaccpa01`,
        img: `ccpa01`,
        title: `CCPA Compliance Solutions `,
        text: `Penta Privacy Lock helps businesses implement CCPA compliance solutions.`,
      },
      {
        id: `pentasupplychain02`,
        name: `rice`,
        img: `supplychain02`,
        title: `Penta Rice Supply Chain`,
        text: `Safe food products and reduction of waste with 'smart' blockchains can be a US$270 billion savings (Boston Consulting) and 30% production increase in the global food supply (United Nations).`,
      },

      {
        id: `pentasmartcities01`,
        name: `pentasmartcities01`,
        img: `smartcities01`,
        title: `Fintech: Loan Syndication and Fractionalized Ownership Platform`,
        text: `Penta is working with a leading real estate lender in California to offer fractionalized loan issuance to investors through a technical platform with blockchain-based asset custody and chain of title.`,
      },
      {
        id: `pentasmartcities02`,
        name: `pentasmartcities02`,
        img: `smartcities02`,
        title: `Smart Cities Waste Management and Recycling`,
        text: `The Penta Network is part of a blockchain solution to track waste management, encouraging everyone within their local communities to separate recyclables and waste responsibly.`,
      },

      /*  {
        id: `pentahealthcare02`,
        name: `pentahealthcare02`,
        img: `healthcare02`,
        title: `Managing Healthcare Cost`,
        text: `A Penta powered insurance account management system, powered by blockchain that enables underwriting and payment settlement for bank customers.`,
      },
      {
        id: `pentahealthcare03`,
        name: `pentahealthcare03`,
        img: `healthcare03`,
        title: `Securing Your Health Records`,
        text: `Redesigning the way patients and providers access healthcare records and  manage privacy. Penta is collaborating with Parkway Health to implement a patient-empowered blockchain for privacy transferrable, secure health records.`,
      },
 */
      {
        id: `pentasocialimpact02`,
        name: `pentasocialimpact02`,
        img: `impact02`,
        title: `Social Impact - Trusted Voices`,
        text: `A unique ‘freedom of content’ platform, innovating how to provide a secure information chain of custody that tracks content across social media platforms and the web. With the goal of 'zero fake news', a new tool for human rights on a global scale. `,
      },
      /*       {
        id: `pentafintech02`,
        name: `pentafintech02`,
        img: `fintech02`,
        title: `SME CreditChain`,
        text: `A credit data-chain project, empowering small businesses and individuals to apply for loans with protections from predatory lending schemes. TrueTab brings trust and accountability to third party managed accounts, multi-stakeholder revenue sharing accounts, and escrow account management services.`,
      }, */
      {
        id: `pentafintech03`,
        name: `pentafintech03`,
        img: `fintech03`,
        title: `TrueTab - Trusted Account Management`,
        text: `Brings trust and accountability to third party managed accounts, multi-stakeholder revenue sharing accounts, and escrow account management services.`,
      },
      {
        id: `pentaun01`,
        name: `pentaun01`,
        img: `un01`,
        title: `Penta @ United Nations`,
        text: `Penta Founders, David Ritter and William Zuo were invited attending the United Nation the 73rd General Assembly on September 28th, 2018, to discuss how cryptocurrencies & blockchain technology can help the U.N. to achieve the Sustainable Development Goals.`,
      },
      {
        id: `dev01`,
        name: `dev01`,
        img: `dev01`,
        title: `Penta Labs`,
        text: `Engaging the research and educations communities to advance blockchain technologies. Hosting workshops in Australia and the United States to grow a vibrant, international developer community.`,
      },
      {
        id: `pentasocialimpact03`,
        name: `pentasocialimpact03`,
        img: `impact03`,
        title: `Penta Outreach`,
        text: `STEM education programs at all school levels. Bringing the ideas of a better decentralized economy to kids in Colorado.`,
      },
      // {
      //   id: `pentaentertainment01`,
      //   name: `pentaentertainment01`,
      //   img: `entertain01`,
      //   title: `London Football Exchange`,
      //   text: `Building the world's first and largest fan-driven blockchain community for football. Penta is successfully developing distributed ledger technologies for the entertainment industry`
      // },
      /* {
        id: `pentasupplychain03`,
        name: `pentasupplychain03`,
        img: `supplychain03`,
        title: `Jack Brown Produce - Tracking Great American Apples`,
        text: `With Internet of Things (IoT) sensors Penta tracks Michigan Apple packing and shipping conditions from start to finish. Secured by Penta blockchain technologies, Jack Brown apples are traceable from 'farm to fork', for highest quality produce.`
      }, */
      {
        id: `pentasupplychain04`,
        name: `pentasupplychain04`,
        img: `supplychain04`,
        title: `LaMeseta Coffee, Premium Quality Across Continents`,
        text: `Compañia Cafetera La Meseta is a family business with a lot of experience in the marketing of premium Colombian coffee. Penta's blockchain-backed supply chain platform brings the LaMeseta story directly to consumers.`,
      },
      {
        id: `pentasupplychain05`,
        name: `pentasupplychain05`,
        img: `supplychain05`,
        title: `Supply Chain for Canadian Oil & Gas`,
        text: `Lack of transparency and complex logistics are some of the issues for oil and gas companies, translating into high cost investments. Penta is meeting those challenges with innovative approaches for end-to-end tracking of petroleum products with flexible, adaptive business modeling applying blockchain technology.`,
      },
      {
        id: `dev02`,
        name: `dev02`,
        img: `dev02`,
        title: `ISO Standards Working Group TC307`,
        text: `International Organization for Standard-ization (ISO) Penta representatives from the Shanghai Team take active roles in the standardisation of blockchain technologies and distributed ledger technologies.`,
      },
      {
        id: `pentaun02`,
        name: `pentaun02`,
        img: `un02`,
        title: `Working with OECD and UNESCO`,
        text: `Penta Global Marketing Coordinator, Florie Zheng attends OECD Blockchain Policy Forum and meets OECD leadership. Following, Florie is invited to speak at UNESCO headquarters to present about blockchain and Penta's Stride project.`,
      },
    ],
  }; //eo data
  const projects = data.featuresExtended.map((d) => (
    <div key={d.name} className="project ">
      <div className="project-inner">
        <div className="testimonial-main mb-32">
          <div className="project-header">
            <div className="projects-image">
              <Img
                className="project-image"
                fluid={images[d.img].childImageSharp.fluid}
                alt={d.title}
              />
            </div>
            <div className="project-name text-center">{d.title}</div>
          </div>

          {/*           <div className="project-body text-justify text-xs">
            <p className="text-xs m-0">{d.text}</p>
          </div> */}
        </div>
        <div className="testimonial-footer" />
      </div>
    </div>
  ));

  return (
    <section className="testimonials section">
      <div className="container">
        <div className="testimonials-inner section-inner">
          <h2
            className="section-title mt-16 text-center"
            style={{ marginBottom: 0 }}
          >
            {data.title}
          </h2>
          <h4
            className="section-title mt-16 text-center"
            style={{ marginBottom: 0 }}
          >
            {data.text}
          </h4>
          <div className="testimonials-wrap pb-32">
            {/*             <div className="project-image-overview">
              <Img
                className="project-image"
                fluid={images[data.img].childImageSharp.fluid}
                alt={data.title}
              />
            </div> */}
          </div>

          <div className="testimonials-wrap">{projects}</div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
