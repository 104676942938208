import SR from "./ScrollReveal"

export const Reveals = () => {
  const doc = document.documentElement
  const classList = document.body.classList
  doc.classList.remove("no-js")
  doc.classList.add("js")
  classList.add("is-boxed")
  classList.add("has-animations")
  classList.add("is-loaded")
  if (!classList.contains("has-animations")) {
    return
  }
  // Reveal animations
  SR.reveal(".features .section-title,  .features-illustration, .feature", {
    delay: 160,
    duration: 600,
    distance: "60px",
    easing: "cubic-bezier(0.215, 0.61, 0.355, 1)",
    origin: "bottom",
    viewFactor: 0.2,
    interval: 150,
  })
  SR.reveal(
    ".feature-extended:nth-child(odd) .feature-extended-body, .feature-extended:nth-child(even) .feature-extended-image",
    {
      duration: 500,
      distance: "40px",
      easing: "cubic-bezier(0.215, 0.61, 0.355, 1)",
      origin: "right",
      viewFactor: 0.5,
    }
  )
  SR.reveal(
    ".feature-extended:nth-child(even) .feature-extended-body, .feature-extended:nth-child(odd) .feature-extended-image",
    {
      duration: 500,
      distance: "40px",
      easing: "cubic-bezier(0.215, 0.61, 0.355, 1)",
      origin: "left",
      viewFactor: 0.5,
    }
  )
  SR.reveal(".pricing-table, .testimonial, .project, .news, .cta-inner", {
    duration: 500,
    distance: "60px",
    easing: "cubic-bezier(0.215, 0.61, 0.355, 1)",
    origin: "bottom",
    viewFactor: 0.5,
    interval: 150,
  })
} //eo Reveals
