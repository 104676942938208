import AnchorLink from "react-anchor-link-smooth-scroll";
import MediaQuery from "react-responsive";
import Particles from "react-particles-js";
import React from "react";
import { Video } from "../Video"; //change # molecules for cellphone
import fb from "@images/socials/facebook-f-brands.svg";
import linkedin from "@images/socials/linkedin-in-brands.svg";
/* import youtube from "@images/socials/youtube-brands.svg"
import telegram from "@images/socials/telegram-brands.svg"
import reddit from "@images/socials/reddit-brands.svg"
import github from "@images/socials/github-brands.svg"
import slack from "@images/socials/slack-hash-brands.svg"
import instagram from "@images/socials/instagram-brands.svg" */
import medium from "@images/socials/medium-brands.svg";
import twitter from "@images/socials/twitter-brands.svg";
const HeroParticles = (props) => {
  const params = {
    particles: {
      number: {
        value: props.particles || 50,
      },
      size: {
        value: 16,
        random: true,
      },
    },
    move: {
      enable: true,
      speed: 0.5,
      direction: "bottom-right",
      random: false,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200,
      },
    },
    line_linked: {
      enable: true,
      random: true,
      distance: 50,
      opacity: 0.4,
      width: 2,
    },
    opacity: {
      value: 0.4,
      random: true,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: "grab",
        },
        onclick: {
          enable: false,
          mode: "bubble",
        },
        resize: true,
      },

      modes: {
        grab: {
          distance: 400,
          line_linked: {
            opacity: 1,
          },
        },
        bubble: {
          distance: 400,
          size: 40,
          duration: 2,
          opacity: 8,
          speed: 2,
        },
        repulse: {
          distance: 100,
          duration: 0.4,
        },
        push: {
          particles_nb: 4,
        },
        remove: {
          particles_nb: 2,
        },
      },
      retina_detect: true,
    },
  };

  return (
    <Particles
      className="particles-wrapper"
      canvasClassName="particles-wrapper"
      height="400"
      params={params}
    />
  );
};
const Hero = (props) => {
  const data = {
    title: `Welcome to Penta Network`,
    text: `Building Smart Technology for the Economy of Tomorrow`,
    cta: `Connect With`,
    label: `Early Access`,
    d: {
      label: `Contact Us`,
      href: `#pentacontact`,
      offset: 70,
      className: `material-button`,
    },
  };

  return (
    <section id="pentahero" className="hero text-center text-light">
      <MediaQuery maxWidth={600}>
        <HeroParticles particles={10} />
      </MediaQuery>
      <MediaQuery minWidth={601}>
        <HeroParticles />
      </MediaQuery>

      <div className="container-sm">
        <div className="hero-inner">
          <div className="hero-copy">
            <h2 className="hero-title mt-16">{data.title}</h2>
            <div className="hero-paragraph">
              <div>{data.text}</div>
              {/*  <div className="hero-cta-title">{data.cta}</div>
            <div className="hero-cta">
                <a
                  href="https://www.facebook.com/PentaGlobalBlockchain/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={fb} alt="facebook link" />
                </a>
                <a
                  href="https://twitter.com/Penta_GF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="twitter link" />
                </a>
                <a
                  href="https://www.linkedin.com/company/penta-global-foundation-pte-ltd/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="linkedin link" />
                </a>
                <a
                  href="https://medium.com/@penta2018"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={medium} alt="Medium link" />
                </a>
              </div>
              <AnchorLink
                className={data.d.className}
                href={data.d.href}
                offset={data.d.offset}
              >
                {data.d.label}
              </AnchorLink> */}
            </div>
            {/*             <div className="hero-video">
              <Video videoId="vCROPqJbpjc"></Video>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
