import React, { Fragment, useContext, useState } from "react"
import { useSpring, config } from "react-spring"
const Context = React.createContext()
export const RewindSpringProvider = function({
  delay = 3000,
  min = 0,
  max = 1,
  children,
}) {
  const [o, setState] = useState({ x: 0 })
  const _config = config.molasses
  _config.mass = 30
  const animatedProps = useSpring({
    reset: true,
    from: o,
    x: max,
    delay: delay,
    config: _config,
    onRest: () => setState({ x: 0 }),
  })

  return (
    <div className="markdown-body">
      <Context.Provider value={animatedProps} children={children} />
    </div>
  )
}

export const RewindSpring = function({ children }) {
  const { x } = useContext(Context)
  return <Fragment>{children(x)}</Fragment>
}
