import React, { Fragment, useEffect } from "react";

//import Dropdown from "./Dropdown";
import { Link } from "gatsby";
import MediaQuery from "react-responsive";
//import AnchorLink from "react-anchor-link-smooth-scroll";
import { slide as Menu } from "react-burger-menu";
import { genericHashLink } from "react-router-hash-link";
//import nanoid from "nanoid";
import smoothscroll from "smoothscroll-polyfill";

const HashLink = (props) => genericHashLink(props, Link);

const Burger = ({ location }) => {
  useEffect(() => smoothscroll.polyfill());
  const onClick = (e) => {
    const overlay = document.getElementsByClassName("bm-overlay");
    overlay.length > 0 && overlay[0].click();
    e.stopPropagation();
  };
  /* let onSelect = (o) => {
    const overlay = document.getElementsByClassName("bm-overlay");
    overlay.length > 0 && overlay[0].click();
    window.open(o.value || "/", "_blank");
  }; */

  const AnchorLinks = (props) => {
    const linkClassName = "bm-menu bm-menu-row site-header-inner external-link";
    /*  const resources = [
      {
        key: nanoid(),
        value: "https://developer.penta.global/#/",
        label: "Developers",
      },
      {
        key: nanoid(),
        value: "https://old.penta.global/news.html",
        label: "Penta News",
      },
      {
        key: nanoid(),
        value: "https://old.penta.global/ecosystem.html?id=2",
        label: "Community",
      },
    ];
 */
    return (
      <div className={props.className}>
        <HashLink
          smooth
          className={linkClassName}
          id="homeid"
          to="/#pentahero"
          {...props}
        >
          Home
        </HashLink>
        {/* <HashLink
          smooth
          className={linkClassName}
          id="aboutid"
          to="/#pentafeatures"
          {...props}
        >
          About
        </HashLink> */}
        {/* <Dropdown
          options={resources}
          onChange={onSelect}
          value={""}
          title="Resources"
        /> */}
        <HashLink
          className={linkClassName}
          id="pentaprojects"
          to="/projects"
          {...props}
        >
          Projects
        </HashLink>
        <HashLink
          className={linkClassName}
          id="pentateam"
          to="/team"
          {...props}
        >
          Team
        </HashLink>
        {/*         <HashLink
          className={linkClassName}
          id="pentanews"
          to="/news"
          {...props}
        >
          News
        </HashLink> */}
        <HashLink
          className={linkClassName}
          id="contactid"
          to="/#pentafacts"
          {...props}
        >
          Contact
        </HashLink>
        <a
          className={linkClassName}
          download="PentaPrivacyPolicy2022.pdf"
          href="/PentaPrivacyPolicy2022.pdf"
        >
          Privacy
        </a>
      </div>
    );
  }; //eo AnchorLinks
  const props = { onClick: onClick };

  return (
    <Fragment>
      <MediaQuery maxWidth={800}>
        <Menu
          width={189}
          pageWrapId={"outercontainerwrapper"}
          outerContainerId={"outerpagewrapper"}
          right
          disableAutoFocus
        >
          <AnchorLinks
            className="bm-menu bm-menu-col site-header-inner"
            {...props}
          />
        </Menu>
      </MediaQuery>
      <MediaQuery minWidth={801}>
        <AnchorLinks
          className="bm-menu bm-menu-row site-header-inner"
          {...props}
        />
      </MediaQuery>
    </Fragment>
  );
};

export default Burger;
