import "@scss/style.scss";

import { graphql, useStaticQuery } from "gatsby";

import BackgroundImage from "gatsby-background-image";
import MediaQuery from "react-responsive";
import React from "react";
const Marquee = (props) => {
  const backdrop = useStaticQuery(graphql`
    query {
      mobile: file(name: { eq: "penta_rice_bg1" }) {
        childImageSharp {
          fluid(maxHeight: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktop: file(name: { eq: "penta_rice_bg3" }) {
        childImageSharp {
          fluid(maxHeight: 1200, maxWidth: 1750) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const imageDataMobile = [
    backdrop.mobile.childImageSharp.fluid,
    `linear-gradient(rgba(21, 15, 36,  0.35), rgba(21, 15, 36, 0.3))`,
  ].reverse();
  const imageDataDesktop = [
    backdrop.desktop.childImageSharp.fluid,
    `linear-gradient(rgba(21, 15, 36,  0.35), rgba(21, 15, 36, 0.3))`,
  ].reverse();

  const d = {
    title: `About Penta`,
    text: [
      `We live in a world of valuable data, and Penta recognizes the need to bring greater transparency, accountability, security, and user privacy into the data processes that power our businesses. Since 2018, the Penta Team has been developing technology for banks and financial institutions, government organizations, Fortune 500 companies, and startups.`,
      //`Penta Network is a Blockchain Services Provider, Offering Base-layer Public Infrastructure and Agile Enterprise Application Solutions. Penta has established itself with successful use cases in banking, supply chain management, financial services, waste management, media and entertainment, and social impact.`,
      // `To create a world of valued, decentralized information, the Penta Network holds to requirements of delivering greater transparency, visibility, and user privacy for critical data-driven processes that empower business productivity and success. `,
      //`Since 2016, the Penta Team has been developing blockchain technology for banks and financial institutions, government agencies, Fortune 500 companies, and SMEs. Founded by David Ritter and Dr. Steve Melnikoff, the Penta Network and its Executive Team have won numerous awards for achievement in blockchain applications for the digital economy.`
    ],
  };

  //button to our projects page
  return (
    <section id="pentamarquee" className="section marquee">
      <div className="marquee-spacer" />
      <MediaQuery minWidth={1224}>
        <BackgroundImage
          className="marquee-image"
          fluid={imageDataDesktop}
        ></BackgroundImage>
        <div className="marquee-inner">
          <h2 className={`text-light text-center`}>{d.title}</h2>
          <p className={`marquee-paragraph text-light text-sm`}>{d.text[0]}</p>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1223}>
        <BackgroundImage className="marquee-image" fluid={imageDataMobile}>
          <div className="marquee">
            <h2 className={`text-light text-center`}>{d.title}</h2>
            <p className={`marquee-paragraph text-light text-sm`}>
              {d.text[0]}
            </p>
          </div>
        </BackgroundImage>
      </MediaQuery>
    </section>
  );
};

export default Marquee;
