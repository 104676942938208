import { animated, useSpring } from "react-spring";

import React from "react";

export const Slide = (props) => {
  const {
    index,
    offsets: { offsetRadius, offsetFromCenter, n, r },
  } = props;
  const getStyle = () => {
    //
    const factor = () => {
      //let x = Math.abs(offsetFromCenter) / (offsetRadius + 1);
      if (r < 0.2) {
        return r;
      } else {
        return 2.1 * r;
      }
    };
    //	let scalingFactor = 1 - Math.abs(offsetFromCenter / (offsetRadius + 1));
    let scalingFactor = 1 - r;
    const distanceFactor = 1 - factor();
    const translateXoffset = 50 * factor();
    let translateX = -50;
    if (offsetRadius !== 0) {
      if (index === 0) {
        translateX = 0;
      } else if (index === n - 1) {
        translateX = -100;
      }
    }

    if (offsetFromCenter > 0) {
      translateX += translateXoffset;
    } else if (offsetFromCenter < 0) {
      translateX -= translateXoffset;
    }
    /*
        o = {
            translateX,
            scalingFactor,
            offsetRadius,
            offsetFromCenter,
            distanceFactor
        }
        */
    return {
      config: props.config,
      transform: `translateY(-55%) translateX(${translateX}%) scale(${scalingFactor})`,
      left: `${
        offsetRadius === 0 ? 50 : 50 + (offsetFromCenter * 50) / offsetRadius
      }%`,
      opacity: distanceFactor,
      zIndex: Math.abs(Math.abs(offsetFromCenter) - 2),
    };
  }; //eo getStyle

  return (
    <animated.div
      className="carousel-slide-container"
      style={useSpring(getStyle())}
    >
      <div className="slide-image-wrapper">{props.content}</div>
      {props.caption && (
        <p className="text-xs text-center mt-16">{props.caption}</p>
      )}
    </animated.div>
  );
};
