import "@scss/style.scss";

import { graphql, useStaticQuery } from "gatsby";

import Img from "gatsby-image";
import React from "react";

//import themeFeaturesQuotaIllustration from '@images/features-quote-illustration.svg';

const News = () => {
  const images = useStaticQuery(graphql`
    query {
      Kasese: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "kasese" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      HyperledgerForum: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "HyperledgerForum" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 350) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      features01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "features" }
      ) {
        childImageSharp {
          fluid(maxWidth: 790, maxHeight: 103) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      Covid01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "coronavirus" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      Tijuana01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "tijuana" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      Algorand01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "algorand" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      CCP01: file(relativeDirectory: { eq: "features" }, name: { eq: "ccp" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      Football01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "london-football-exchange" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      Huobi01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "huobi" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      Conference01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "MIT-conference" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      Hacklab01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "hacklab" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      Economy01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "stock-price-increase" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      Transparent01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "transparent-path" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      UNESCO01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "unesco" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      supplychain04: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "rodrigo-flores-T5qjs-63kqQ-unsplash" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      supplychain02: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "rice" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      Impact01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "impact-ecosystem" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      Agritech01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "wilson-agritech" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      Emerge01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "trusted-voices" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
    }
  `);
  const data = {
    title: `Latest News`,
    text: `Read how Penta is advancing blockchain technology around the world`,
    img: `features01`,
    featuresExtended: [
      {
        id: `Kasese`,
        name: `Kasese`,
        img: `Kasese`,
        title: `Penta Network Launches Supply Chain Partnership with the Uganda Ministry of Agriculture – Data to Be Hosted on CARI.net Servers`,
        text: ``,
        link: `https://www.cari.net/blog/penta-network-launches-supply-chain-partnership-with-the-uganda-ministry-of-agriculture-data-to-be-hosted-on-cari-net-servers/`,
      },
      {
        id: `HyperledgerForum`,
        name: `HyperledgerForum`,
        img: `HyperledgerForum`,
        title: `No Pain, No Blockchain: Reducing the Complexity of Network Deployment - Ed Kazmierczak at the 2020 Global Hyperledger Forum`,
        text: ``,
        link: `https://www.youtube.com/watch?v=wxL85129tzI`,
      },
      {
        id: `Covid01`,
        name: `Covid01`,
        img: `Covid01`,
        title: `Emerge and Penta Network Support Public COVID-19 Response with Civitas`,
        text: ``,
        link: `https://medium.com/@pentagf/emerge-and-penta-network-support-public-covid-19-response-with-civitas-4bd620af152e`,
      },
      {
        id: `Hyperledger01`,
        name: `Hyperledger01`,
        img: `Tijuana01`,
        title: `A 21st Century Model for Collaboration`,
        text: ``,
        link: `https://www.hyperledger.org/blog/2020/05/04/a-21st-century-model-for-collaboration`,
      },
      {
        id: `Algorand01`,
        name: `Algorand01`,
        img: `Algorand01`,
        title: `Penta CTO, Dr. Steve, Joins Silvio Micali at Blockchain Centre for Algorand’s Launch in Australia`,
        text: ``,
        link: `https://medium.com/@pentagf/penta-cto-dr-steve-joins-silvio-micali-at-blockchain-centre-for-algorands-launch-in-australia-8e0ec1d58ab2`,
      },
      {
        id: `EastAfrica01`,
        name: `EastAfrica01`,
        img: `Agritech01`,
        title: `EAST AFRICA’S JOURNEY INTO THE DIGITAL ECONOMY`,
        text: ``,
        link: `https://medium.com/@pentagf/east-africas-journey-into-the-digital-economy-da816b7b623d`,
      },
      {
        id: `CCP01`,
        name: `CCP01`,
        img: `CCP01`,
        title: `Penta Announces Investment in Shares of ASX Listed Company, CCP Technologies, Using Penta’s PNT Cryptocurrency`,
        text: ``,
        link: `https://medium.com/penta-network/penta-announces-investment-in-shares-of-axs-listed-company-ccp-technologies-using-pentas-pnt-9f7ac1269d41`,
      },
      {
        id: `Football01`,
        name: `Football01`,
        img: `Football01`,
        title: `LFE and Penta Partnership Announcement`,
        text: ``,
        link: `https://medium.com/penta-network/lfe-and-penta-partnership-announcement-4b797fcf0360`,
      },
      {
        id: `Huobi01`,
        name: `Huobi01`,
        img: `Huobi01`,
        title: `Huobi Introduces Penta to Europe During London Meetup`,
        text: ``,
        link: `https://medium.com/penta-network/huobi-introduces-penta-to-europe-during-london-meetup-4b4bdf2d4282`,
      },
      {
        id: `Conference01`,
        name: `Conference01`,
        img: `Conference01`,
        title: `Blockchain Fever Reaches MIT’s Annual Startup Conference`,
        text: ``,
        link: `https://medium.com/penta-network/blockchain-fever-reaches-mits-annual-startup-conference-49e1826d89d0`,
      },
      {
        id: `Hacklab01`,
        name: `Hacklab01`,
        img: `Hacklab01`,
        title: `Penta was a proud sponsor of West Africa’s largest blockchain hackathon, Hacklab 2019. Check out the Hacklab 2019 official report.`,
        text: ``,
        link: `https://www.linkedin.com/pulse/penta-proud-sponsor-west-africas-largest-blockchain-hackathon-ritter/`,
      },
      {
        id: `Economy01`,
        name: `Economy01`,
        img: `Economy01`,
        title: `Building a More Inclusive Economy with Blockchain – By David Ritter, CEO of Penta Global Foundation`,
        text: ``,
        link: `https://smartereum.com/17344/building-a-more-inclusive-economy-with-blockchain-by-david-ritter-ceo-of-penta-global-foundation/`,
      },
      {
        id: `Transparent01`,
        name: `Transparent01`,
        img: `Transparent01`,
        title: `Transparent Path and Penta Network join forces to reduce financial, reputational and health risks for food producers and consumers.`,
        text: ``,
        link: `https://medium.com/@penta2018/transparent-path-and-penta-network-join-forces-to-reduce-financial-reputational-and-health-risks-74bf8d22b8f6`,
      },
      {
        id: `UNESCO01`,
        name: `UNESCO01`,
        img: `UNESCO01`,
        title: `AI and Blockchain Could Transform Education: Penta’s Florie Zheng Shares Her Thoughts on UNESCO Mobile Learning Week.`,
        text: ``,
        link: `https://medium.com/@penta2018/ai-and-blockchain-could-transform-education-pentas-florie-zheng-shares-her-thoughts-on-unesco-3684b297bf23`,
      },
      {
        id: `Coffee01`,
        name: `Coffee01`,
        img: `supplychain04`,
        title: `In Bogota, Colombia for Penta's Blockchain Coffee. We're doing coffee traceability with top coffee companies here.`,
        text: ``,
        link: `https://www.linkedin.com/pulse/bogota-colombia-pentas-blockchain-coffee-were-doing-top-david-ritter/?trackingId=yKBikuIIeq7WePes9v3jnw%3D%3D`,
      },
      {
        id: `Impact01`,
        name: `Impact01`,
        img: `Impact01`,
        title: `The Penta Impact Ecosystem is Revolutionizing the Way We Build Inclusive Communities`,
        text: ``,
        link: `https://medium.com/@penta2018/the-penta-impact-ecosystem-is-revolutionizing-the-way-we-build-inclusive-communities-5fa9972c4619`,
      },
      {
        id: `Agritech01`,
        name: `Agritech01`,
        img: `Agritech01`,
        title: `Blockchain Meets Agritech: Food Security and Supply Chain Management`,
        text: ``,
        link: `https://medium.com/@penta2018/blockchain-meets-agritech-food-security-and-supply-chain-management-5eaa3bad3c25`,
      },
      {
        id: `Emerge01`,
        name: `Emerge01`,
        img: `Emerge01`,
        title: `Emerge and The Penta Network Launch Trusted Voices, A Platform To Promote Information Transparency For News Content`,
        text: ``,
        link: `https://medium.com/@penta2018/emerge-and-the-penta-network-launch-trusted-voices-a-platform-to-promote-information-transparency-e3ed3c111883`,
      },
    ],
  }; //eo data
  const projects = data.featuresExtended.map((d) => (
    <div key={d.name} className="news">
      <a
        href={d.link ? d.link : `https://www.google.com/`}
        style={{ textDecoration: "none", color: "black" }}
      >
        <div className="news-inner">
          <div className="mb-8 ml-8 mt-8">
            <Img
              className="news-image"
              fluid={images[d.img].childImageSharp.fluid}
              alt={d.title}
            />
            <div className="news-information">
              <div className="news-header">
                <div className="project-name text-center">{d.title}</div>
              </div>
              <div className="project-body text-right text-xs">
                <p className="text-xs m-0">{d.text}</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  ));

  return (
    <section className="testimonials section">
      <div className="container">
        <div className="testimonials-inner section-inner">
          <h2
            className="section-title mt-16 text-center"
            style={{ marginBottom: 0 }}
          >
            {data.title}
          </h2>
          <h4
            className="section-title mt-16 text-center"
            style={{ marginBottom: 0 }}
          >
            {data.text}
          </h4>
          <div className="testimonials-wrap pb-16">
            <div className="project-image-overview">
              <Img
                className="project-image"
                fluid={images[data.img].childImageSharp.fluid}
                alt={data.title}
              />
            </div>
          </div>

          <div className="news-wrap">{projects}</div>
        </div>
      </div>
    </section>
  );
};

export default News;
