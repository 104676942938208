import "@scss/style.scss";

import { graphql, useStaticQuery } from "gatsby";

import Img from "gatsby-image";
import { Link } from "gatsby";
import React from "react";

//import themeFeaturesQuotaIllustration from '@images/features-quote-illustration.svg';
//enlarge images ~40%
const FeaturesExtended = (props) => {
  const images = useStaticQuery(graphql`
    query {
      supplychain01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "shippingContainer" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      fintech01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "markus-spiske-3Tf1J8q9bBA-unsplash" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      smartcities01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "smartCities01" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      healthcare01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "jesse-orrico-rmWtVQN5RzU-unsplash" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      impact01: file(
        relativeDirectory: { eq: "features" }
        name: { eq: "impactGoals" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
    }
  `);
  const data = {
    title: `Our Initiatives`,
    text: `Penta deploys commercial grade data management technologies for businesses, public sector partners and social impact projects all over the world. Contact us to find out how you can bring transparency, accountability, and security to your business applications. `,
    // text: `Building the world’s most economically inclusive and decentralized blockchain ecosystem, with a high-performance platform capable of supporting the digital economy at scale.`,
    d: {
      id: "pentaprojects",
      label: "Learn More",
      to: "/projects/",
      className: `material-button learn-more`,
    },
    featuresExtended: [
      {
        id: `pentasupplychain`,
        name: `pentasupplychain`,
        img: `supplychain01`,
        title: `Supply Chain - Transparent, Efficient, Sustainable`,
        text: `Penta delivers instrumented blockchain-backed supply chains to monitor and track product conditions from "farm to fork". As waste, cost and health problems: an innovative solution for establishing and maintaining consumer trust.`,
      },
      /* {
				id: `pentasupplychain02`,
				name: `rice`,
				img: `supplychain02`,
				title: `Blockchain Rice, Real-World Use Case`,
				text: `Safe food products and reduction of waste with 'smart' blockchains can be a US$270 billion savings (Boston Consulting) and 30% production increase in the global food supply (United Nations).`
			}, */
      {
        id: `pentafintech`,
        name: `pentafintech`,
        img: `fintech01`,
        title: `Fintech - Realizing Decentralized, Global Business`,
        text: `Embedding blockchain fintech for: cross-border payment/remittance systems, third-party account and tax management, credit/lending services, insurance settlement, digital wallets.`,
      },
      /* {
				id: `pentafintech02`,
				name: `pentafintech02`,
				img: `fintech02`,
				title: `SME CreditChain / TrueTab`,
				text: `A credit data-chain project, empowering small businesses and individuals to apply for loans with protections from predatory lending schemes. TrueTab brings trust and accountability to third party managed accounts, multi-stakeholder revenue sharing accounts, and escrow account management services.`
			}, */
      {
        id: `pentasmartcities`,
        name: `pentasmartcities`,
        img: `smartcities01`,
        title: `Smart Cities - Blockchain for a Sustainable World`,
        text: `A web of interconnecting technologies for the modern smart city to improve commerce, city life and infrastructure.
                Gartner is projecting almost 10 billion IoT devices will connect up smart cities by 2020, including using streetlights as wide area networks for 'hyper' data collection.
                `,
      },
      /* {
				id: `pentasmartcities02`,
				name: `pentasmartcities02`,
				img: `smartcities02`,
				title: `Blockchain Garbage`,
				text: `The Penta Network is part of a blockchain solution to track waste management, encouraging everyone within their local communities to separate recyclables and waste responsibly.`
			}, */
      {
        id: `pentahealthcare`,
        name: `pentahealthcare01`,
        img: `healthcare01`,
        title: `Healthcare - Freedom, Privacy and Affordability`,
        text: `Patient-centric, transformative healthcare backed by blockchain technology. New health record exchanges that are at their core more efficient and secure. Real-time tracability of all perscription medications, towards solving the country's opiod crisis.`,
      },
      /* {
				id: `pentahealthcare02`,
				name: `pentahealthcare02`,
				img: `healthcare02`,
				title: `Managing Healthcare Cost, Ensuring Doctors and Hospitals are Affordable`,
				text: `A Penta powered insurance account management system, powered by blockchain that enables underwriting and payment settlement for bank customers.`
			},
			{
				id: `pentahealthcare03`,
				name: `pentahealthcare03`,
				img: `healthcare03`,
				title: `Your Health Records and Secure Access`,
				text: `Redesigning the way patients and providers access healthcare records and  manage privacy. Penta is collaborating with Parkway Health to implement a patient-empowered blockchain for privacy transferrable, secure health records.`
			}, */

      {
        id: `pentasocialimpact`,
        name: `pentasocialimpact`,
        img: `impact01`,
        title: `Social Impact - Blockchain for Trusted Voices`,
        text: `Opportunities for real transformation in the ecosystem of sustainable development and social impact, towards resolving some of the world's biggest challenges.`,
      } /* ,
			{
				id: `pentasocialimpact02`,
				name: `pentasocialimpact02`,
				img: `impact02`,
				title: `Trusted Voices: A Proud Penta / Emerge Partnership`,
				text: `A unique ‘freedom of content’ platform, innovating how to provide a secure information chain of custody that tracks content across social media platforms and the web. With the goal of 'zero fake news', a new tool for human rights on a global scale. `
			},
			{
				id: `pentasocialimpact03`,
				name: `pentasocialimpact03`,
				img: `impact03`,
				title: `Penta for Public Policy and Governance`,
				text: `In the public sector, Penta is participating at all levels with the hope of providing robust strategies that utilize blockchain to realize its great potential as a powerful technology platform for democratic ideals.`
			} */,
    ],
  }; //eo data
  const featuresExtended = data.featuresExtended.map((d) => (
    <div id={d.id} key={d.name} className="feature-extended">
      <div className="feature-extended-image is-revealing">
        <Img fluid={images[d.img].childImageSharp.fluid} alt={d.title} />
      </div>
      <div className="feature-extended-body is-revealing">
        <h3 className="feature-extended-body-title mt-0 mb-0">{d.title}</h3>
        <Link className={data.d.className} to={data.d.to}>
          {data.d.label}
        </Link>
      </div>
    </div>
  ));
  return (
    <section id="pentainiatives" className="features-extended section">
      <div className="container">
        <div className="features-extended-inner section-inner has-bottom-divider">
          <div className="features-extended-header text-center">
            <div className="features-extended-container">
              <h2 className="section-title mt-8">{data.title}</h2>
              <p className="section-paragraph">{data.text}</p>
            </div>
          </div>
          {/*  <div className="features-extended-wrap">
            <div className="container">{featuresExtended}</div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default FeaturesExtended;
