import React from "react"
import "@scss/style.scss"
import upArrow from "@images/up-arrow.svg"
import AnchorLink from "react-anchor-link-smooth-scroll"

const FAB = props => {
  return (
    <AnchorLink className="button-fab mb-8" href={`#pagewrapper`}>
      <img src={upArrow} alt="Up Arrow" />
    </AnchorLink>
  )
}

export default FAB
