import React from "react";
import "@scss/style.scss";
import { animated } from "react-spring";
import { RewindSpringProvider, RewindSpring } from "./Spring";
import shipSolid from "@images/icons/ship-solid.svg";
import appleAltSolid from "@images/icons/apple-alt-solid.svg";
import mugHotSolid from "@images/icons/mug-hot-solid.svg";
import projectDiagramSolid from "@images/icons/project-diagram-solid.svg";
//import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Contact } from "@components/Contact";

const CTA = props => {
  const FunFacts = props => {
    const data = {
      title: `Fun Penta Facts`,
      link: `/#`,
      linkLabel: `Get Started`,
      facts: [
        {
          icon: shipSolid,
          title: "Global Tracking",
          val: 4359,
          desc: "Coffee Across Two Continents"
        },
        {
          icon: appleAltSolid,
          title: "Safe Food Transport",
          val: 510933,
          desc: "Tasty Apples from Michigan"
        },
        {
          icon: mugHotSolid,
          title: "Engineers Drinking",
          val: 758,
          desc: "With Amazing Technologies "
        },
        {
          icon: projectDiagramSolid,
          title: "Partners - Investors",
          val: 38,
          desc: "Growing to Scale"
        }
      ]
    }; //eo facts data
    return (
      <div id="pentafunfacts" className="section-inner  cta-inner">
        <div className="cta-cta-column">
          <div className="cta-cta-row">
            <h2 className="section-title text-center mt-8">{data.title}</h2>
          </div>
          <RewindSpringProvider>
            <div className="cta-cta-row">
              <div className="cta-cta">
                <div className="cta-title">{data.facts[0].title}</div>
                <img src={data.facts[0].icon} alt={data.facts[0].title} />
                <RewindSpring>
                  {x => (
                    <animated.div className="cta-spring">
                      {x.interpolate(
                        n => (data.facts[0].val * n).toFixed(0) + ` km`
                      )}
                    </animated.div>
                  )}
                </RewindSpring>
                <div className="cta-desc">{data.facts[0].desc}</div>
              </div>
              <div className="cta-cta">
                <div className="cta-title">{data.facts[1].title}</div>
                <img src={data.facts[1].icon} alt={data.facts[1].title} />
                <RewindSpring>
                  {x => (
                    <animated.div className="cta-spring">
                      {x.interpolate(n => (data.facts[1].val * n).toFixed(0))}
                    </animated.div>
                  )}
                </RewindSpring>
                <div className="cta-desc">{data.facts[1].desc}</div>
              </div>
            </div>
            <div className="cta-cta-row">
              <div className="cta-cta">
                <div className="cta-title">{data.facts[2].title}</div>
                <img src={data.facts[2].icon} alt={data.facts[2].title} />
                <RewindSpring>
                  {x => (
                    <animated.div className="cta-spring">
                      {x.interpolate(
                        n => (data.facts[2].val * n).toFixed(0) + " Cups"
                      )}
                    </animated.div>
                  )}
                </RewindSpring>
                <div className="cta-desc">{data.facts[2].desc}</div>
              </div>
              <div className="cta-cta">
                <div className="cta-title">{data.facts[3].title}</div>
                <img src={data.facts[3].icon} alt={data.facts[3].title} />
                <RewindSpring>
                  {x => (
                    <animated.div className="cta-spring">
                      {x.interpolate(n => (data.facts[3].val * n).toFixed(0))}
                    </animated.div>
                  )}
                </RewindSpring>
                <div className="cta-desc">{data.facts[3].desc}</div>
              </div>
            </div>
          </RewindSpringProvider>
        </div>
      </div>
    );
  }; //eo funFacts

  return (
    <section id="pentafacts" className="section cta">
      <div className="container">
        <div className="cta-wrap">
          <FunFacts />
          <Contact />
        </div>
      </div>
    </section>
  );
};

export default CTA;
