import "@scss/style.scss";

import React, { Fragment, useEffect } from "react";

import { Awards } from "./Awards";
//import { Pricing } from './Pricing';
import { CTA } from "./CTA";
import { FAB } from "./FAB";
import { Features } from "./Features";
import { FeaturesExtended } from "./FeaturesExtended";
import Footer from "./Footer/Footer";
import { Header } from "./Header";
import { Helmet } from "react-helmet";
import { Hero } from "./Hero";
import { Marquee } from "./Marquee";
import { News } from "./News";
import { Partners } from "./Partners";
import { Projects } from "./Projects";
import { Reveals } from "./reveals";
import { Testimonials } from "./Testimonials";

const Layout = (props) => {
    useEffect(Reveals, []); //eo useEffect
    const title = "Penta Solutions";
    const description =
        "Penta Solutions and the Penta Network is developing technologies and building a better economy with blockchain";
    const image = "https://penta.solutions/static/images/pentaHero.jpg";
    const url = "https://penta.solutions";
    const creator = "Steve Melnikoff steve.melnikoff@pentanetwork.org";
    const homePage = () => (
        <Fragment>
            <Helmet>
                {/* General tags */}
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="image" content={image} />
                <link rel="canonical" href={url} />

                {/* OpenGraph tags */}
                <meta property="og:url" content={url} />
                <meta property="og:site_name" content={title} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={creator} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
            </Helmet>
            <Hero />
            <Marquee />
            {/*       <Awards />
      <Features />  */}
            <FeaturesExtended />
            {/*  <Partners /> */}
            <CTA />
            <FAB />
            <Footer />
        </Fragment>
    );

    const teamPage = () => <Testimonials />;
    const projectsPage = () => <Projects />;
    const newsPage = () => <News />;
    return (
        <div id="outerpagewrapper" className="boxed-container">
            <div id="pagewrapper" className="body-wrap">
                <Header location={props.location} />
                <main id="outercontainerwrapper">
                    {props.homepage && homePage()}
                    {props.teampage && teamPage()}
                    {props.projectspage && projectsPage()}
                    {props.newspage && newsPage()}
                </main>
            </div>
        </div>
    );
};

export default Layout;
