import "@scss/style.scss";

import React from "react";
import themeLogo from "@images/ico_24.svg";
//import { SVG } from "../SVG"

const Footer = () => {
  const data = {
    title: `Getting Started`,
    text: `Drop Us a Note`,
    subtitle: `Penta US`,
    themeLogo: themeLogo,
    copyright: `2022 Penta, all rights reserved`,
  };
  return (
    <footer id="pentagetstarted" className="site-footer">
      <div className="container">
        <div className="site-footer-inner">
          <div className="site-footer-bottom">
            <a href="/#">
              <img src={data.themeLogo} alt={data.subtitle} />
            </a>
            <div className="footer-copyright">&copy; {data.copyright}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
