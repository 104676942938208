import React, { useState, useEffect, Fragment } from "react";
import { Slide } from "./Slide";
import { config } from "react-spring";
const Carousel = props => {
  const [index, setIndex] = useState(0);
  const [ptrs] = useState([]);
  const init = () => {
    const { offsetRadius } = props;
    const modBySlidesLength = index => {
      //circular array
      const { slides } = props;
      const mod = (a, b) => ((a % b) + b) % b;
      let i = mod(index, slides.length);
      return i;
    }; //eo modBySlidesLength
    //get initial array of slides to use, then shift on update
    for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {
      ptrs.push(modBySlidesLength(index + i));
    }
  };
  const effect = () => {
    const GOTO_INTERVAL = 4000; //idle time before sliding
    const id = setInterval(() => {
      updatePtrs();
      setIndex(i => i + 1);
    }, GOTO_INTERVAL);
    const cleanup = () => {
      if (id) clearInterval(id);
    };

    // init() //one time initialization
    return cleanup; //eo cleanup */
  }; //eo effect
  useEffect(effect, []); //eo useEffect once

  const getOffsets = index => {
    const { slides, offsetRadius } = props;
    let clampedOffsetRadius = offsetRadius;
    const upperBound = Math.floor((slides.length - 1) / 2);
    clampedOffsetRadius = offsetRadius < 0 ? 0 : clampedOffsetRadius;
    clampedOffsetRadius =
      offsetRadius > upperBound ? upperBound : clampedOffsetRadius;
    const offsetFromCenter = index - clampedOffsetRadius;
    const n = 2 * clampedOffsetRadius + 1;
    const r = Math.abs(offsetFromCenter) / (clampedOffsetRadius + 1);
    return {
      index: index,
      offsetRadius: clampedOffsetRadius,
      offsetFromCenter: offsetFromCenter,
      n: n,
      r: r
    };
    // return clampedOffsetRadius;
  }; //eo clampOffsetRadius

  const updatePtrs = () => {
    //shift array to the left, ptrs is an array of the slide indexes to use
    const { slides, direction } = props;
    ptrs &&
      ptrs.forEach((p, i) => {
        if (direction < 0) {
          --p;
          ptrs[i] = p < 0 ? slides.length - 1 : p;
        } else {
          ++p;
          ptrs[i] = p < slides.length ? p : 0;
        }
      });
  };

  const getPresentableSlides = () => {
    const { slides } = props;
    const presentableSlides = [];
    ptrs &&
      ptrs.forEach(p => {
        if (!presentableSlides.find(o => o.key === slides[p].key)) {
          presentableSlides.push(slides[p]);
        }
      });
    return presentableSlides;
  }; //eo getPresentableSlides
  const className = props.className
    ? `carousel-wrapper ${props.className}`
    : `carousel-wrapper`;
  init();
  return (
    <Fragment>
      <div className={className}>
        {getPresentableSlides().map((slide, i) => (
          <Slide
            key={slide.key}
            content={slide.content}
            offsets={getOffsets(i)}
            index={i}
            config={props.config}
            caption={slide.caption}
          />
        ))}
      </div>
    </Fragment>
  );
};

Carousel.defaultProps = { offsetRadius: 4, config: config.default };

export default Carousel;
