import { graphql, useStaticQuery } from "gatsby";

import Img from "gatsby-image";
import React from "react";

const Testimonials = (props) => {
  const people = useStaticQuery(graphql`
    query {
      david: file(relativeDirectory: { eq: "people" }, name: { eq: "david" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      ed: file(relativeDirectory: { eq: "people" }, name: { eq: "ed" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      lucia: file(relativeDirectory: { eq: "people" }, name: { eq: "lucia" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      william: file(
        relativeDirectory: { eq: "people" }
        name: { eq: "william" }
      ) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      charles: file(
        relativeDirectory: { eq: "people" }
        name: { eq: "charles" }
      ) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      steve: file(
        relativeDirectory: { eq: "people" }
        name: { eq: "Steve_500x500" }
      ) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      zetlen: file(
        relativeDirectory: { eq: "people" }
        name: { eq: "Zetlen2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
      telpner: file(
        relativeDirectory: { eq: "people" }
        name: { eq: "Telpner" }
      ) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid_withWebp
            originalName
          }
        }
      }
    }
  `);
  const d = {
    title: "Global Experience Across All Sectors",
    text: "A Great Team from Three Continents",
  };
  const data = [
    {
      name: `David Ritter`,
      title: `Chief Executive`,
      img: `david`,
      desc: `David Ritter is the CEO of Penta. Receiving his degree in financial and sociopolitical history from USC, Mr. Ritter served as Project Director of DGI, managing projects with a portfolio value of more than USD$1 billion. David also has managed a Hollywood P&A Fund in partnership with major studios. As Penta CEO, in 2018 Mr. Ritter was invited by MIT as the blockchain expert for its Global Startup Workshop forum. David is also an active philanthropist and community advocate.`,
    },
    {
      name: `Steve Melnikoff`,
      title: `Chief Technology Officer`,
      img: `steve`,
      desc: `Dr. Melnikoff is chief scientist, and with David Ritter co-founder of Penta. He has degrees in physics and mathematics from MIT, receiving his PhD in physics from UC Riverside. Starting at the Lawrence Livermore National Lab, Steve has continued a career in information physics with domain expertise in large-scale, web-based data systems. He has worked on advanced government related projects in both the public and private technology sectors, as well as in university research.`,
    },
    {
      name: `Dr. Ed Kazmierczak`,
      title: `Products Engineering Lead`,
      img: `ed`,
      desc: `Dr. Ed Kazmierczak holds a PhD in computer science and has served both as researcher and professor at the University of Melbourne. His area is software engineering for large-scale distributed high integrity systems. Projects include partnerships with the Australian Defence Sciences and Technology Organisation (DSTO) and in-depth physiological modelling of the human kidney. Recently Ed has been involved in the design of in-ground sensor networks for crop monitoring and multispectral image data integration.`,
    },
    {
      name: `Lucia Gallardo`,
      title: `Social Impact Director`,
      img: `lucia`,
      desc: `Lucia Galladro is a Honduran entrepreneur and the Founder & CEO of Emerge. Focussing on technology for social impact, she is currently nominated for the 2018 Global SDG Awards and Newsweek’s 2019 Blockchain Impact Awards. Prior to Emerge Lucia co-founded Dona un Libro, Cambia una Mente, an education centered non-profit and 2016 recipient of President Obama’s Young Leaders of the Americas Fellowship. Among her many awards is the 2017 Venture for Canada Fellowship. She has experience in both public and private sectors: from immigration and trade relations on diplomatic missions, to mobile tech and early-stage investments.`,
    },
    /* {
      name: `William Zuo`,
      title: `Asian Markets Director`,
      img: `william`,
      desc: `William Zuo is CEO and founder of Gingkoo, a leading Chinese Fintech and blockchain company. His education includes MIT Sloan Business School Executive MBA, Master of CS (Hehai University), and was GM for the publicly listed China Software International; Vice MG of China listed company Kelan Software (300663). He has 16 years IT and Fintech experience: development of China Union Pay, China central bank CNAPS payment system, as well as payment systems for more than 10 global banks including CITI bank, and HSBC. William was a keynote speaker at the UN General Assembling and the OECD Blockchain Conference 2018. 
`,
    }, */
    /*  {
      name: `Charles Cai`,
      title: `Strategic Planning Advisor`,
      img: `charles`,
      desc: `Charles Cai is a strategic planning advisor for Penta. His professional roles includes Chief Data Intelligence Officer of McDonald’s China, in charge of strategy, IT and big data; CEO and Co-founder of InfiniVision ABC Labs; Previously Senior Advisor to the President and Chief Data Officer, Chief Architect of Wanda Technology Group. Charles has more than two decades of business innovation experience; was Chief Architect in investment banks and energy trading companies in London building Big Data and Cloud Computing CoE ($100M budget/year). A frequent keynote speaker at various international conferences, Charles was voted among Top 50 UK’s Data Leaders and Influencers of 2015/2016.`
    }, */
    /*     {
      name: `Bryan Zetlen`,
      title: `Government Projects Coordinator`,
      img: `zetlen`,
      desc: `Bryan Zetlen is an experienced senior government program and technology manager, with an extensive background helping drive the success of complex technology/engineering initiatives for organizations around the globe. His portfolio ranges from startups to government agencies to Fortune 500 enterprises. Mr. Zetlen’s focus is on technology transfer and IP commercialization, with over 120 projects in the aerospace, engineering, biotechnology, energy, and manufacturing sectors. Bryan has served as a policy advisor for local/federal governments and International organisations including: the United Nations, the Organization of American States, and the Governments of China, United Kingdom, EU, Australia, Canada, Argentina, Slovakia, New Zealand, and Costa Rica.`,
    }, */
    {
      name: `Joel Telpner`,
      title: `Fintech and Legal Advisor`,
      img: `telpner`,
      desc: `Joel is a highly sought-after legal advisor in the fintech space. Joel brings more than 30 years of legal experience in a career that includes time as an AmLaw 100 partner, the former U.S. general counsel of a global financial institution and a venture capitalist.  Joel leads policy initiatives on global regulatory issues on behalf of the Chamber of Digital Commerce. Joel was named as one of the Top 6 Fintech Leading Lawyers in the United States in The Legal 500 U.S. 2019 Edition; and one of the top 10 Fintech attorneys in America for 2019 by Chambers & Partners.`,

      //  desc: `Joel is a highly sought-after legal advisor in the fintech and blockchain space. Joel brings more than 30 years of legal experience in a career that includes time as an AmLaw 100 partner, the former U.S. general counsel of a global financial institution and a venture capitalist.  Joel leads policy initiatives on global regulatory issues for blockchain on behalf of the Chamber of Digital Commerce, the Global Blockchain Business Council and the Blockchain Research Institute. He is also a member of the Wall Street Blockchain Alliance’s Legal Working Group, which is developing guidance on best practices for the blockchain industry. Joel led the discussion "ICOs – Best Practices" at the World Economic Forum’s Blockchain Central in Davos, Switzerland in January 2018. Joel was named as one of the Top 6 Fintech Leading Lawyers in the United States in The Legal 500 U.S. 2019 Edition; and one of the top 10 Fintech attorneys in America for 2019 by Chambers & Partners.`,
    },
  ];
  const testimonials = data.map((d) => (
    <div key={d.name} className="testimonial is-revealing">
      <div className="testimonial-inner">
        <div className="testimonial-main mb-32">
          <div className="testimonial-header">
            <div className="testimonial-image">
              <Img fluid={people[d.img].childImageSharp.fluid} alt={d.name} />
            </div>
            <div className="testimonial-name text-sm">
              {d.name}
              <br />
              <span className="testimonial-title text-xs">{d.title}</span>
            </div>
          </div>
          <div className="testimonial-body text-justify text-xs">
            <p className="text-xs m-0">{d.desc}</p>
          </div>
        </div>
        <div className="testimonial-footer" />
      </div>
    </div>
  ));
  return (
    <section className="testimonials section">
      <div className="container">
        <div className="testimonials-inner section-inner">
          <h2
            className="section-title mt-32 text-center"
            style={{ marginBottom: 0 }}
          >
            {d.title}
          </h2>
          <h4
            className="section-title mt-16 text-center"
            style={{ marginBottom: 32 }}
          >
            {d.text}
          </h4>
          <div className="testimonials-wrap">{testimonials}</div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
