import React from "react"
import themeLogo from "@images/ico_24_purple.svg"
//import AnchorLink from "react-anchor-link-smooth-scroll"
import { Link } from "gatsby"

import { BurgerWrapper } from "../Burger"

const Header = props => {
  const logo = {
    themeLogo: themeLogo,
    title: `PENTA`,
  }

  //		<AnchorLink href={d.href} key={d.name} className="feature anchor-link text-center is-revealing">
  const Logo = props => {
    return (
      <div className="site-header-inner">
        <Link to="/" className="brand anchor-link header-brand">
          <h3 className="m-0 header-brand-inner">
            <img
              className="header-logo-image"
              src={props.themeLogo}
              alt="{data.title}"
            />
            <span className="header-brand-title">{props.title}</span>
          </h3>
        </Link>
      </div>
    )
  }

  return (
    <header
      id="siteheader"
      className="container navbar  site-header has-animations"
    >
      <Logo {...logo} />
      <BurgerWrapper location={props.location} />
    </header>
  )
}

export default Header
