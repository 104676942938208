//import React, { useState, useContext } from "react";
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import store from "store";
import "notyf/notyf.min.css";
import { Notyf } from "notyf";
//import NotyfContext from "../../helpers/context/NotyfContext";

const Contact = () => {
  const [state, setState] = useState(
    store.get("contact") || { email: "", name: "", message: "" }
  );
  let notyf = null;
  if (typeof document !== "undefined") {
    //notyf = useContext(NotyfContext);
    notyf = new Notyf({
      duration: 1000,
      types: [
        {
          type: "success",
          className: "notyfX",
          backgroundColor: "#663399",
          duration: 10000
        }, //eo error
        {
          className: "notyfX",
          type: "error",
          duration: 10000
        } //eo error
      ] //eo types
    }); //eo new Notyf
  }

  const data = {
    title: `Contact Us`,
    d: {
      label: `Send`,
      href: `#pentacontact`,
      offset: 70,
      className: `material-button`
    }
  };
  const validate = values => {
    let errors = {};
    if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      values.email = "Invalid Email Address";
    }
    return errors;
  };

  const onSubmit = (values, { setSubmitting }) => {
    const checkValues = () => {
      let flag = true;
      if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        flag = false;
      }
      if (!values.message && !values.email) flag = false;
      if (
        values.message &&
        values.message.length === 0 &&
        values.email &&
        values.email.length === 0
      )
        flag = false;
      return flag;
    };
    const addMessage = async (url, data) => {
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: "omit", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json"
        },
        //redirect: "follow", // manual, *folslow, error
        //referrer: "client", // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      response.json().then(json => {
        // do something with your data
        setSubmitting(false);
      });
    };
    const sendOut = values => {
      // const url = `https://us-central1-pentaexpress01.cloudfunctions.net/addMessage`;
      //const url = `https://pentaexpress01.web.app/addMessage`;
      const url = `https://penta.solutions/addMessage`;
      //alert(JSON.stringify(values, null, 2));
      setState(values);
      store.set("contact", values);
      notyf.success({
        duration: 2000,
        message: "Thanks, We Appreciate the Message!"
      });
      addMessage(url, values);
    };
    const noSend = () => {
      notyf.success({
        duration: 2000,
        message: "Thanks, Before Sending Please Fill in the Fields!"
      });
    };
    setTimeout(() => {
      checkValues(values) ? sendOut(values) : noSend();
    }, 400);
  };

  const formik = ({ isSubmitting }) => (
    <div id="pentacontact" className="section-inner  cta-inner">
      <div className="cta-cta-column">
        <div className="cta-cta-row">
          <h2 className="section-title text-center mt-8">{data.title}</h2>
        </div>
        <Form className="wrap-contact100">
          <div className="wrap-input100">
            <Field
              className="input100"
              name="name"
              placeholder="Full Name"
              type="text"
            />
            <ErrorMessage name="name" component="div" />
          </div>
          <div className="wrap-input100">
            <Field
              className="input100"
              name="email"
              placeholder="Email"
              type="email"
            />
            <ErrorMessage name="email" component="div" />
          </div>
          <div className="wrap-input100">
            <Field
              component="textarea"
              className="input100"
              name="message"
              placeholder="Your Message"
            />
            <ErrorMessage name="message" component="div" />
          </div>

          <button
            className="material-button contact100-button"
            type="submit"
            // disabled={isSubmitting}
          >
            {data.d.label}
          </button>
        </Form>
      </div>
    </div>
  );
  return (
    <Formik initialValues={state} validate={validate} onSubmit={onSubmit}>
      {formik}
    </Formik>
  );
}; //eo contact

export default Contact;
